<template>
  <div>
    <div style="margin: 14px 0; display: flex">
      <div>
        <Input
          :maxlength="20"
          v-model.trim="name"
          placeholder="请输入姓名"
          style="width: 200px; margin-right: 10px"
        ></Input>
        <Input
          :maxlength="20"
          v-model.trim="archiveNum"
          placeholder="请输入档案编号"
          style="width: 200px; margin-right: 10px"
        ></Input>
        <DatePicker
          type="month"
          v-model="yearofmonth"
          style="width: 200px; margin-right: 10px"
          placeholder="请选择月份"
          class="datePicker"
          :editable="false"
        ></DatePicker>
        <Button type="primary" @click="search" style="margin-right: 10px"
          >查询</Button
        >
        <Button type="success" @click="reset">重置</Button>
      </div>
      <div
        style="
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 400px;
        "
      >
        <div style="margin-right: 26px; display: flex; align-items: center">
          <span
            style="
              display: inline-block;
              width: 20px;
              height: 20px;
              margin-right: 6px;
              background: #19be6b;
            "
          ></span>
          已按计划完成
        </div>
        <div style="margin-right: 26px; display: flex; align-items: center">
          <span
            style="
              display: inline-block;
              width: 20px;
              height: 20px;
              margin-right: 6px;
              background: #ed4014
            "
          ></span>
          未按计划完成
        </div>
        <div style="margin-right: 26px; display: flex; align-items: center">
          <span style="margin-right: 6px">——</span>
          未安排
        </div>
      </div>
    </div>
    <LiefengTable
      :talbeColumns="talbeColumns"
      :tableData="tableData"
      :total="total"
      :curPage="page"
      @hadlePageSize="hadlePageSize"
      :pagesizeOpts="[20, 30, 50, 100]"
      :loading="loading"
      :fixTable="true"
      :pageSize="pageSize"
    ></LiefengTable>
  </div>
</template>

<script>
import LiefengTable from "@/components/LiefengTable";
export default {
  props: ["serviceYearofmonth"],
  data() {
    return {
      name: "",
      archiveNum: "",
      yearofmonth: "",
      talbeColumns: [],
      tableData: [],
      total: 0,
      page: 1,
      loading: false,
      pageSize: 20,
    };
  },
  methods: {
    search() {
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize,
      });
    },
    reset() {
      this.name = "";
      this.archiveNum = "";
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize,
      });
    },
    hadlePageSize(obj) {
      this.$post("/archives/api/pc/servicePlan/queryServiceProcessPage", {
        page: obj.page,
        pageSize: obj.pageSize,
        yearofmonth: this.$core.formatDate(
          new Date(this.yearofmonth),
          "yyyyMM"
        ),
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        name: this.name,
        archiveNum: this.archiveNum,
      })
        .then((res) => {
          if (res.code == 200 && res.dataList) {
            this.page = res.currentPage;
            this.total = res.maxCount;
            this.pageSize = res.pageSize;
            this.tableData = res.dataList;
            this.talbeColumns = [
              {
                title: "姓名",
                width: 150,
                key: "name",
                fixed: "left",
                align: "center",
              },
              {
                title: "档案编号",
                width: 200,
                key: "archiveNum",
                align: "center",
              },
            ];
            if (res.dataList[0] && res.dataList[0].serviceProcessProjectVos) {
              res.dataList[0].serviceProcessProjectVos.map((item,index) => {
                this.talbeColumns.push({
                  title: item.name,
                  minWidth: 200,
                  align: "center",
                  render: (h, params) => {
                    return h(
                      "div",
                      {
                        style: {   //status:0：未安排；1：未完成；2：已完成;
                          lineHeight: "30px",
                          color:
                            params.row.serviceProcessProjectVos[index].status == '2' ||
                            params.row.serviceProcessProjectVos[index].status == '1'
                              ? "#fff"
                              : "",
                          backgroundColor:
                            params.row.serviceProcessProjectVos[index].status == '2'
                              ? "#19be6b" 
                              : params.row.serviceProcessProjectVos[index].status == '1'
                              ? "#ed4014"
                              : "",
                        },
                      },
                      params.row.serviceProcessProjectVos[index].status == '0'
                        ? "——"  
                        : (params.row.serviceProcessProjectVos[index].status == '1' ||
                          params.row.serviceProcessProjectVos[index].status == '2') &&
                          params.row.serviceProcessProjectVos[index].serviceNumber != 0
                        ? "已服务" + params.row.serviceProcessProjectVos[index].serviceNumber + "次"
                        : (params.row.serviceProcessProjectVos[index].status == '1' ||
                          params.row.serviceProcessProjectVos[index].status == '2') &&
                          params.row.serviceProcessProjectVos[index].serviceNumber == 0 
                        ? "未服务"
                        : ""
                    );
                  },
                });
              });
            }
          } else {
            this.$Message.error({
              backgroud: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.$Message.error({
            backgroud: true,
            content: "获取数据失败",
          });
        });
    },
  },
  created() {
    this.yearofmonth =
      this.serviceYearofmonth.substring(0, this.serviceYearofmonth.length - 2) +
      "-" +
      this.serviceYearofmonth.substring(this.serviceYearofmonth.length - 2);
    this.search();
  },
  components: {
    LiefengTable,
  },
};
</script>
    
<style scoped lang='less'>
.datePicker {
    /deep/.ivu-icon-ios-close-circle {
    display: none;
}
}

</style>